/* eslint-disable max-lines */
import PubNub from 'pubnub'

import { getCloudinaryUrl } from './CloudinaryHelpers'
import { dateToUnix } from './DateHelpers'


let instance = null

export const getPubNubInstance = (userUUID, force) => {
  if (!instance || instance.getUUID() !== userUUID || force) {
    instance = new PubNub({
      publishKey: process.env.PUBNUB_PUBLISH_KEY, // allow publishing of messages
      subscribeKey: process.env.PUBNUB_SUBSCRIBE_KEY, // allow subscribing to channel
      uuid: userUUID, // unique for each client
      restore: true, // enable catchup on missed messages
      // authKey: 'authKey', //required if PAM is enabled on the keyset
    })
  }

  return instance
}

export const reconnect = (pubnub) => pubnub.reconnect()

export const getUuid = (user) => `${user.id}_${user.first_name.replace(/\s/g, '')}_${user.last_name.replace(/\s/g, '')}`

export const setUserMetadata = async(pubnub, uuid, user) => {
  const avatar = user.avatar ? user.avatar : 'defaults/avatar'

  const cloudinaryURL = await getCloudinaryUrl(avatar, true, false, { width: 50, height: 50 })

  pubnub.objects.setUUIDMetadata({
    data: {
      uuid,
      name: `${user.first_name} ${user.last_name}`,
      profileUrl: cloudinaryURL,
      custom: {
        company: user?.company?.name || '',
      },
    },
  })
}

export const setUserMetadataForExternalUser = async(uuid, user) => {
  const pubnub = getPubNubInstance(uuid, true)

  const avatar = user.avatar ? user.avatar : 'defaults/avatar'

  const cloudinaryURL = await getCloudinaryUrl(avatar, true, false, { width: 50, height: 50 })

  pubnub.objects.setUUIDMetadata({
    data: {
      uuid,
      name: `${user.first_name} ${user.last_name}`,
      profileUrl: cloudinaryURL,
      custom: {
        company: user?.company?.name || '',
        createdAt: new Date(),
      },
    },
  })
}


export const setUserMetadataForBusiness = async(pubnub, uuid, offer) => {
  const cloudinaryURL = await getCloudinaryUrl('defaults/logo', true, false, { width: 50, height: 50 })

  pubnub.objects.setUUIDMetadata({
    data: {
      uuid,
      name: offer.companyName || 'Entreprise',
      profileUrl: cloudinaryURL,
      custom: {
        company: offer.title || '',
        createdAt: new Date(),
      },
    },
  })
}

export const setUserMetadataForExternalBusiness = async(uuid, offer) => {
  const pubnub = getPubNubInstance(uuid)

  const cloudinaryURL = await getCloudinaryUrl('defaults/logo', true, false, { width: 50, height: 50 })

  pubnub.objects.setUUIDMetadata({
    data: {
      uuid,
      name: offer.companyName || 'Entreprise',
      profileUrl: cloudinaryURL,
      custom: {
        company: offer.title || '',
        createdAt: new Date(),
      },
    },
  })
}

export const getUserMetadata = (pubnub, uuid) => pubnub.objects.getUUIDMetadata({ uuid })

export const getAllUsersMetadata = (pubnub) => pubnub.objects.getAllUUIDMetadata()

export const generateChannelID = (user1, user2) => {
  const user1ID = parseInt(user1.id, 10)
  const user2ID = parseInt(user2.id, 10)

  return user1ID > user2ID ? `channel_${user2ID}_${user1ID}` : `channel_${user1ID}_${user2ID}`
}

export const generateChannelOfferID = (user, offer) => {
  const userID = parseInt(user.id, 10)
  const offerID = parseInt(offer.id, 10)
  const offerAdministratorID = parseInt(offer.user.id, 10)

  return userID > offerID
    ? `channel_offer_${offerID}_${userID}_admin${offerAdministratorID}`
    : `channel_offer_${userID}_${offerID}_admin${offerAdministratorID}`
}

export const getTimetoken = (pubnub) => pubnub.time()

export const setMembershipToChannel = async(pubnub, uuid, channel) => {
  const result = await getTimetoken(pubnub)

  const arg = {
    uuid,
    channels: [{ id: channel, custom: { lastReadTimetoken: result.timetoken } }],
  }

  pubnub.objects.setMemberships(arg)
  return { channel: { id: channel }, custom: { lastReadTimetoken: result.timetoken } }
}

export const getMembershipToChannel = (pubnub, uuid) => pubnub.objects.getMemberships({
  uuid,
  include: { customFields: true },
})

export const removeMembershipToChannel = async(pubnub, uuid, channel) => pubnub.objects.removeMemberships({
  uuid,
  channels: [channel],
  include: { customFields: true, channelFields: true, customChannelFields: true },
})

export const getChannelMembers = (pubnub, channel) => pubnub.objects.getChannelMembers({
  channel,
  include: {
    UUIDFields: true,
  },
})

export const getChannelMetadata = (pubnub, channel) => pubnub.objects.getChannelMetadata({ channel })

export const getUnreadCountFromChannel = async(pubnub, channel, timetoken) => pubnub.messageCounts({
  channels: [channel],
  channelTimetokens: [timetoken],
})

export const fetchMessages = async(pubnub, channels, count) => pubnub.fetchMessages({
  channels,
  count,
})

export const convertTimetokenToUnixTimestamp = (timetoken) => Math.floor(timetoken / 10000000)

export const convertUnixTimestampToTimetoken = (unixTimestamp) => unixTimestamp * 10000000


const isNewAndUncontacted = (channel) => {
  if (!channel.createdAt) {
    return false
  }

  const DELAY = 10 * 60 * 1000 // new channel created in the last 10 minutes will be prioritized
  const createdAtTime = new Date(channel.createdAt).getTime()
  const now = Date.now()

  return now - createdAtTime < DELAY && channel.lastMessageTimetoken === null
}

// eslint-disable-next-line complexity
export const sortChannelsByTimetoken = (channelA, channelB) => {
  const isNewAndUncontactedA = isNewAndUncontacted(channelA)
  const isNewAndUncontactedB = isNewAndUncontacted(channelB)

  if (isNewAndUncontactedA && !isNewAndUncontactedB) {
    return -1
  }
  if (!isNewAndUncontactedA && isNewAndUncontactedB) {
    return 1
  }

  const lastMsgTimeA = channelA.lastMessageTimetoken
  const lastMsgTimeB = channelB.lastMessageTimetoken

  if (lastMsgTimeA && lastMsgTimeB) {
    return lastMsgTimeB - lastMsgTimeA
  }

  if (lastMsgTimeA) {
    return -1
  }
  if (lastMsgTimeB) {
    return 1
  }

  // using the timetoken as a fallback is not ideal because the timetoken is updated even if the user is just switching to this channel, so the order of the channels will change
  return 0
}

export const generateChannelMetadata = async(pubnub, userChannels, currentUserUUID, currentChannel) => {
  const lastMessages = await fetchMessages(pubnub, userChannels.map((c) => c.id), 1)

  const usersChannelsWithMetadata = await Promise.all(
    // eslint-disable-next-line complexity
    userChannels?.map(async(channel) => {
      const members = await getChannelMembers(pubnub, channel.id)
      const contact = members.data.filter((m) => m.uuid.id !== currentUserUUID)
      const contactMetadata = await getUserMetadata(pubnub, contact?.[0]?.uuid?.id)

      if (contactMetadata.data.id === currentUserUUID) {
        return null
      }

      let unreadCount = channel.timetoken ? await getUnreadCountFromChannel(pubnub, channel.id, channel.timetoken) : 0

      // Force unreadCount to 0 if currentChannel is the same as the one we are checking right now
      // (because pubnub is slow to update, we force it to 0 when we switch to another channel and now we double check it here too)
      if (currentChannel === channel.id && unreadCount !== 0) {
        unreadCount = 0
      }

      return {
        ...contactMetadata.data,
        id: channel.id,
        unreadCount: unreadCount !== 0 ? unreadCount.channels[channel.id] : 0,
        eTag: '',
        description: contactMetadata.data?.custom?.company || '',
        custom: {
          profileUrl: contactMetadata.data?.profileUrl,
          thumb: contactMetadata.data?.profileUrl,
        },
        timetoken: channel.timetoken,
        lastMessageTimetoken: lastMessages.channels?.[channel.id]?.[0]?.timetoken || null,
        createdAt: contactMetadata.data?.custom?.createdAt || null,
      }
    })
  )

  return usersChannelsWithMetadata
    .filter((c) => c !== null)
    .sort((a, b) => (a.name > b.name ? 1 : -1))
}


export const generateTemporaryChannelMetadata = async(pubnub, channelId, user) => ({
  id: channelId,
  name: `${user.first_name} ${user.last_name}`,
  externalId: null,
  profileUrl: user.avatar,
  email: null,
  custom: {
    profileUrl: user.avatar,
    thumb: user.avatar,
  },
  updated: new Date().toISOString(),
  eTag: '',
  unreadCount: 0,
  description: user.company?.name || '',
  timetoken: convertUnixTimestampToTimetoken(dateToUnix(new Date())),
  lastMessageTimetoken: convertUnixTimestampToTimetoken(dateToUnix(new Date())),
})


export const getPubnubIssuesStatus = (pubnub) => ['PNTimeoutCategory', 'PNNetworkIssuesCategory', 'PNNetworkDownCategory']
