import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'


export const key = 'chat'

//
// Initial state
//
const initialState = {
  pnStatus: '',
  currentChannel: '',
  unreadMessagesCount: 0,
  listen: false,
}

//
// Actions
//
export const actions = {
  setPnStatus: createAction('Chat: setPnStatus', (pnStatus) => pnStatus),
  setCurrentChannel: createAction('Chat: setCurrentChannel', (currentChannel) => currentChannel),
  setUnreadMessagesCount: createAction('Chat: setUnreadMessagesCount', (unreadMessagesCount) => unreadMessagesCount),
  listen: createAction('Chat: listen', (listen) => listen),
  sendNotification: createAction('Chat: sendNotification', (data) => ({ data })),
}

//
// Reducer
//
export const reducer = createReducer({
  [actions.setPnStatus]: (state, pnStatus) => Immutable.static.merge(state, {
    pnStatus,
  }),
  [actions.setCurrentChannel]: (state, currentChannel) => Immutable.static.merge(state, {
    currentChannel,
  }),
  [actions.setUnreadMessagesCount]: (state, unreadMessagesCount) => Immutable.static.merge(state, {
    unreadMessagesCount,
  }),
  [actions.listen]: (state, listen) => Immutable.static.merge(state, {
    listen,
  }),
  [actions.sendNotification]: (state, data) => Immutable.static.merge(state, {
    data,
  }),
}, initialState)

//
// Selectors
//
const root = (state) => state[key]
const pnStatus = (state) => root(state)?.pnStatus
const currentChannel = (state) => root(state)?.currentChannel
const unreadMessagesCount = (state) => root(state)?.unreadMessagesCount
const listen = (state) => root(state)?.listen

export const selectors = {
  pnStatus,
  currentChannel,
  unreadMessagesCount,
  listen,
}
